<template>
    <div class="pa-0 ma-0">
        <tr>
            <td>
                <div>
                    {{item.clave_tipo_nomina}} - {{item.descripcion}}
                </div>
            </td>
            <td>
                <div>
                    {{item.numero_periodo}}
                </div>
            </td>
            <td>
                <div>
                    {{dateFormat(item.fecha_de_pago, 'tabla')}}
                </div>
            </td>
            <td style="min-width:300px!important; max-width:311px!important; width:310px; padding-left:8px; padding-right:8px;">
                <div class="tblOpciones">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonHover" icon fab dark small>
                            <v-icon class="tamIconoBoton invertirColorBotones">remove_red_eye</v-icon>
                        </v-btn>
                        </template>
                        <span>Previsualizar</span>
                    </v-tooltip>
                </div>
            </td>
            <td style="min-width:79px!important; max-width:81px!important; width:80px; padding-left:8px;">
                <div class="tblOpciones" v-if="empleados.length > 0">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon fab dark small @click="isOpen ? isOpen = false:isOpen = true">
                            <v-icon class="tamIconoBoton iconoDelgadoBoton">{{isOpen ? 'keyboard_arrow_up':'keyboard_arrow_down'}}</v-icon>
                        </v-btn>
                        </template>
                        <span>Desplegar</span>
                    </v-tooltip>
                </div>
            </td>
        </tr>
        <template v-if="isOpen">
            <v-row class="pa-0 ma-0">
                <v-col cols="12" xs="12" sm="12" md="12" class="px-5">
                    <table class="rounded-xl elevation-2">
                        <thead class="rounded-xl">
                            <tr class="rounded-t-xl">
                                <th class="rounded-tl-xl" style="min-width:309px!important; max-width:330px!important; width:331px;">Empleado</th>
                                <th>Total percepciones</th>
                                <th>Total deducciones</th>
                                <th class="rounded-tr-xl">Neto a pagar</th>
                            </tr>
                        </thead>
                        <tbody class="rounded-b-xl">
                            <tr :key="empleado.id" v-for="empleado in empleados"  class="td-short">
                                <td style="min-width:309px!important; max-width:330px!important; width:331px;">
                                    <v-list class="py-0 my-0" style="background:transparent;">
                                        <v-list-item class="py-0 my-0 px-0">
                                            <v-list-item-avatar class="py-0 my-0">
                                                <v-img class="py-0 my-0" :src="urlImg+empleado.foto"></v-img>
                                            </v-list-item-avatar>
                                            <v-list-item-content class="ml-0 py-0 my-0">
                                                <v-list-item-title class="tblPrincipal">{{ empleado.nombre_completo }}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </td>
                                <td>
                                    {{formatNum(empleado.total_percepciones)}}
                                </td>
                                <td>
                                    {{formatNum(empleado.total_deducciones)}}
                                </td>
                                <td>
                                    {{formatNum(empleado.neto_pagar)}}
                                </td>
                            </tr>
                            <tr class="rounded-b-xl">
                                <td class="rounded-bl-xl">
                                </td>
                                <td>
                                </td>
                                <td>
                                </td>
                                <td class="rounded-br-xl">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </v-col>
            </v-row>
        </template>
    </div>
</template>


<script>
import GetUrlImg from "@/plugins/Urlimagen";

export default {
    name: 'expandable-item',
    props: {
        item: {
            type: Object,
            required: true,
            default: ''
        },
    },
    data() {
        return {
            isOpen: false,
            urlImg : '',
        }
    },
    /**
    * @description COMPUTED: Las funciones de este tipo se tratan como variables reactivas y sirven para que la misma variable haga 
    * una funcion para formatar los datos y regresar un nuevo valor en vez de crear funciones noramles y 
    * tener que llamarlas. Estas apenas se asignen se ejecutaran y ellas mismas regrsaran el nuevo resultado.
    */
    computed: {
        empleados(){
            let empleados = JSON.parse(JSON.stringify(this.item.empleados));
            return empleados;
        }
    },
    methods: {
        dateFormat(fecha, tipo) {
            let fecha2 = fecha.split("-");
            if(tipo == 'modal'){
                return fecha2[2] + "/" + fecha2[1]+ "/" + fecha2[0];
            }else{
                return fecha2[2] + " / " + fecha2[1]+ " / " + fecha2[0];
            }
        },
        /**
         * @method formatNum Esta método parsea un número
         * @description Convierte un número a formato moneda, formato pesos mexicanos 
         * @param {number} num Valor númerico que puede tener números decimales.
         * @return {string} devuelve un string en formato $0.00
        */
        formatNum(num){
            const formatter = new Intl.NumberFormat('es-MX', {
                style: 'currency',
                currency: 'MXN',
            })
            return formatter.format(num);
        },  
    },
    mounted () {
        this.urlImg = GetUrlImg.getURL();
    },
}
</script>

<style scoped>
    /**
    * TRANSITION EFFECT
    */
    .slide-enter-active,
    .slide-leave-active {
        transition-duration: 0.8s;
        transition-property: opacity;
        transition-timing-function: ease;
    }

    .slide-enter,
    .slide-leave-active {
    opacity: 0
    }

    /*STATUS*/
    .renovar{
        border: solid 0.5px #f7e6c3 !important;
        color: #E19117;
        padding:11px 0px;
        border-radius: 8px;
        width: 175px;
    }
    .incompleto{
        border: solid 0.5px #ffc9cc !important;
        color: #F03738;
        padding:11px 0px;
        border-radius: 8px;
        width: 100px;
    }
    
    .vencido{
        border: solid 0.5px #828282 !important;
        color: #828282;
        padding:11px 0px;
        border-radius: 8px;
        width: 91px;
    }
    .firmado{
        border: solid 0.5px #c5f7cd !important;
        color: #3CC13B;
        padding:11px 0px;
        border-radius: 8px;
        width: 80px;
    }
    .listo{
        border: solid 0.5px #ccd3e2 !important;
        color: #325687;
        padding:11px 0px;
        border-radius: 8px;
        width: 135px;
    }
    .filter {
        color: #96999a;
        cursor: pointer;
    }
    .inline-block {
        display: inline-block;
    }
    table {
        border-collapse: collapse;
        width: 100%;
        overflow-x: scroll;
    }
    th{
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        text-align: left;
        color: #828282;
        background-color: #f6f5f6;
        padding: 13px 45px 13px 45px;
    }
    th > div{
        max-width: 15rem;
        text-align: center;
        margin-right: auto;
        margin-left: auto;
    }
    td{
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        text-align: left;
        color: #1E2245;
        background-color: #fdfcfc;
        padding: 13px 45px 13px 45px;
        letter-spacing: -0.34px;
        overflow: hidden;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        max-width: 15rem;
    }

    td > div{
        overflow: hidden;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        max-width: 15rem;
        margin-right: auto;
        margin-left: auto;
    }
    td > .loading{
        max-width: 100% !important;
    }
    tbody {
      display:block;
      max-height:60vh;
      min-width: 1000px;
      background-color: #fdfcfc;
    }
    tr {
      display:table;
      table-layout:fixed;
      width: 100%;
    }
    table.ck-table.modal  tbody {
      display:block;
      max-height:60vh;
      min-width: 700px;
      background-color: #fdfcfc;
    }

    .td-short td{
        padding-top:1px !important;
        padding-bottom:1px !important;
    }

    tbody > div {
        vertical-align: middle !important;
    }
    
</style>