<template>
    <div id="pageTable">
        <v-overlay :value="loadingLottie"  :z-index="999" >
            <div class="align-center justify-center text-center">
                <v-progress-circular
                    indeterminate
                    :size="100"
                    :width="7"
                    color="#2DB9D1"
                >
                    <v-img
                        src="/static/icon/favicon.ico"
                        :width="60"
                    ></v-img>
                </v-progress-circular>
                <p class="mt-5">{{fraseLoading}}</p>
            </div>
        </v-overlay>    
        <v-container xl fluid>
            <v-row>
                <v-col cols="12" lg="12">
                    <data-table
                        ref="tabla"
                        :tableName="'Desacumulados'"
                        :url="url"
                        :columns="columns"
                        :filters="filters"
                        @setFilters="setFilters"
                        @loadModalData="loadModalData"
                        :showAdvancedFilters="true"
                        :perPage="[10,25,50,100]"
                    >
                     <template slot="filters">
                            <v-col v-if="rol == 'root'" cols="12" xs="12" sm="6" md="6" class="py-0">  
                                <v-autocomplete
                                    outlined
                                    label="Clientes"
                                    :items="itemsClientes"
                                    item-text="nombre"
                                    item-value="id"
                                    persistent-hint
                                    v-model="cliente_value"
                                    class="pa-0 ma-0"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col v-if="rol == 'root' || rol == 'admin'" xs="12" sm="6" md="6" class="py-0">
                                <v-autocomplete
                                    outlined
                                    :items="itemsEmpresas"
                                    item-text="nombre"
                                    item-value="id"
                                    label="Empresa"
                                    persistent-hint
                                    v-model="empresa_value"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                    <v-autocomplete
                                        outlined
                                        label="Tipo de nómina"
                                        :items="itemsTiposNominas"
                                        item-text="claveDescripcion"
                                        item-value="id"
                                        persistent-hint
                                        v-model="tipo_nomina_id"
                                        class="pa-0 ma-0"
                                        id="tipoNominaID"
                                    >
                                    </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                    <v-autocomplete
                                        outlined
                                        label="Periodo"
                                        :items="itemsPeriodos"
                                        item-text="numero"
                                        item-value="id"
                                        persistent-hint
                                        v-model="periodo_value"
                                        class="pa-0 ma-0"
                                    >
                                    </v-autocomplete>
                            </v-col>
                            <v-col xs="12" sm="12" md="12">
                                <div class="RangoIngreso">
                                    <span>Rango de fechas</span>
                                </div>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                <VuetifyDatePicker
                                    outlined
                                    v-model="fecha_inicio"
                                    label="Fecha inicio"
                                    placeholder="dd/mm/aaaa"
                                    ref="FechaInicio"
                                />
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                <VuetifyDatePicker
                                    outlined
                                    v-model="fecha_final"
                                    label="Fecha final"
                                    placeholder="dd/mm/aaaa"
                                    ref="FechaFinal"
                                />
                            </v-col>
                        </template>
                        <tbody slot="body" slot-scope="{ data }">
                            <template v-for="(item,index) in data">
                                <expandable-item 
                                    :item="item"
                                    :key="index" 
                                >
                                </expandable-item>
                            </template>
                        </tbody>
                    </data-table>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import Vue from 'vue';
import Datatable from '@/components/datatable/Datatable';
import Notify from '@/plugins/notify';
import GetUrlImg from "@/plugins/Urlimagen";
import apiClientes from "@/api/clientes";
import apiTipoNomina from '@/api/nominas/tipoNomina';
import apiPeriodos from '@/api/nominas/periodos';
import apiEmpresas from '@/api/empresas'
import apiCalculoNomina from '@/api/nominas/calculoNomina';
import Lottie from 'vue-lottie';
import animationData from '@/animations/cliker.json';
import LoadingCliker from '@/components/LoadingCliker';
import VuetifyDatePicker from '@/components/VuetifyDatePicker.vue';
import ExpandableItem from '@/components/ExpandableItemTimbres.vue';


export default {
    components: {
        'data-table': Datatable,
        'lottie': Lottie,
        'loading-cliker':LoadingCliker,
        VuetifyDatePicker,
        ExpandableItem,
    },
    data() {
        return {
            datosLogin      : null,
            rol             : null,
            urlImg          : null,
            url             : "nominas-timbres/lista_desacumulados",
            columns         : [
                {
                    label: 'Tipo nómina',
                    name: 'clave_tipo_nomina',
                    filterable: false
                },
                {
                    label: 'Periodo',
                    name: 'numero_periodo',
                    filterable: false
                },
                {
                    label: 'Fecha de pago',
                    name: 'fecha_de_pago',
                    filterable: false
                },
                {
                    label: 'Opciones',
                    name: 'opciones',
                    align: 'center',
                    filterable: false
                },
                {
                    label: "",
                    name: "desplegar",
                    align: 'center',
                    filterable: false,
                },
            ],
            filters         : {
                activo    :true,
                paginate  :true,
                order     :"asc",
                cliente_id: null,
                acumulado : true,
            },
            tableData       : {},
            paginationData  : {
                numberItems : 10,
                numberPage  : 1
            },
            calculoNom                      : {
                id                          : null,
                tipo_nomina_id              : null,
                periodo_id                  : null,
                incidencias_checador        : false,
                incidencias_capturadas      : false,
                modificaciones_adicionales  : false,
                acumulado                   : false,
                modificaciones_campos       : {
                    empresa_id              : null,
                    comision                : 0,
                    iva                     : 0,
                    aplica_retencion_iva    : false,
                    aplica_retencion_isr    : false,
                },
            },
            userData                : this.$session.get('usuario'),
            accion                  : null,
            tituloModal             : null,
            dialog                  : false,
            isLoading               : false,
            isSaving                : false,
            imagen                  : '',
            items_empleados         : [],
            modelo_empleados        : [],
            itemsTiposNominas       : [],
            tipo_nomina_id          : null,
            itemsPeriodos           : [],
            calendar_pago           : false,
            incidencias_reloj_checador  : "NO",
            incidencias_capturadas      : "NO",
            modificaciones_adicionales  : "NO",
            empresa_id              : null,
            itemsEmpresas           : [],
            comision                : 0,
            iva                     : 0,
            retencion_iva           : "NO",
            retencion_isr           : "NO",
            itemsClientes           : [],
            cliente_value           : null,
            empresa_value           : null,
            periodo_value           : null,
            fecha_inicio          : null,
            fecha_final           : null,
            fechaPago               : null,
            cambio_empleado         : false,
            imagenAvatar            : "/static/avatar/usuario.jpg",
            showGuardar             : true,
            isLoadingCalculo        : true,
            dialogCalculo           : false,
            imagenCalculo           : '',
            tituloModalCalculo      : 'Cálculo de nómina',
            itemCalculo             : null,
            dialogDownload          : false,
            imagenDownload          : "/static/icon/catalogos.png",
            tituloModalDownload     : "Descargando archivo",
            defaultOptions          : { animationData: animationData },
            loadingLottie           : false,
            fraseLoading            : 'Timbrando',
        }
    },
    watch: {
        cliente_value: function(val){
            if(val!= null){
               this.getEmpresas(val);
            }
        },
        empresa_value: function(val){
            if(val != null){
                this.getTiposNominas(); 
            }
        },
        tipo_nomina_id: function(val){
            if(val != null){
                this.getPeriodos(val);
                //this.getEmpleados(val);
            } 
        },
        modificaciones_adicionales: function(val){
            if(val == 'NO'){
                document.getElementById("tipoNominaID").focus();
            }
        }
    },
    methods: {
        getPaginationInfo(data) {
            switch(data.type) {
                case "page":
                    this.paginationData.numberPage = data.value
                    break;

                case "itemsToShow":
                    this.paginationData.numberItems = data.value
                    break;
            }
        },
        abrirModal(accion, data = null){
        },
        cerrarModal() {
            this.dialog     = false;
            this.isSaving   = false;
            this.dialogCalculo =  false;
            this.resetValues();
        },
        resetValues() {
            this.calculoNom                 = {
                id                          : null,
                tipo_nomina_id              : null,
                periodo_id                  : null,
                incidencias_checador        : false,
                incidencias_capturadas      : false,
                modificaciones_adicionales  : false,
                acumulado                   : false,
                modificaciones_campos       : {
                    empresa_id              : null,
                    comision                : 0,
                    iva                     : 0,
                    aplica_retencion_iva    : false,
                    aplica_retencion_isr    : false,
                },
            },
            this.incidencias_reloj_checador  = "NO";
            this.incidencias_capturadas      = "NO";
            this.modificaciones_adicionales  = "NO";

            this.empresa_id         = null;
            this.cliente_value      = null;
            this.empresa_value      = null;
            this.itemsEmpresas      = [];
            this.comision           = 0;
            this.iva                = 0;
            this.retencion_iva      = "NO";
            this.retencion_isr      = "NO"; 
            this.fechaPago          = null;
            this.itemsClientes      = [];
            this.tipo_nomina_id     = null;
            this.items_empleados    = [];
            this.modelo_empleados   = [];
            this.itemsTiposNominas  = [];
            this.itemsPeriodos      = [];
            this.showGuardar        = true;

            this.cambio_empleado = false;
            this.$nextTick(() => {
                this.$refs.form.reset();
            });
        },
        guardar() {

        },
        async loadModalData() {
            await this.resetValues();
            if (this.rol == "root") {
                this.getClientes();
            } else if (this.rol == "admin") {
                this.cliente_value = this.datosLogin.cliente_id;
            } else {
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
            }
        },
        setFilters() {
            let cliente = this.cliente_value;
            let empresa = this.empresa_value;
            let tipo_nomina = this.tipo_nomina_id;
            let periodo_value = this.periodo_value;
            let fecha_inicio = this.fecha_inicio;
            let fecha_final = this.fecha_final;
            

            if (this.rol !== "root") {
                cliente = this.datosLogin.cliente_id;
                if (this.rol !== "admin") {
                    empresa = this.datosLogin.empresa_id;
                } 
            } 

            let filterParams = {
                cliente_id: cliente,
                empresa_id: empresa,
                tipo_nomina_id: tipo_nomina,
                periodo_id: periodo_value,
                min_fecha_inicio: fecha_inicio,
                max_fecha_final: fecha_final
            };

            let defaultParams = {
                activo    :true,
                paginate  :true,
                order     :"asc",
                acumulado : true,
                tipo_periodo_especial: false
            };

            if (filterParams != null) Object.assign(defaultParams, filterParams);

            this.filters = defaultParams;
            this.cliente_value = null;
            this.itemsEmpresas = [];
            this.empresa_value = null;
            this.itemsTiposNominas = [];
            this.tipo_nomina_id = null;
            this.itemsPeriodos = [];
            this.periodo_value = null;
            if(this.$refs.FechaInicio != undefined || this.$refs.FechaInicio != null){
                this.$refs.FechaInicio.resetDate();
            }
            if(this.$refs.FechaFinal != undefined || this.$refs.FechaFinal != null){
                this.$refs.FechaFinal.resetDate();
            }
        },
        async getClientes(){
            let paramClientes = {
                activo      : true, 
                paginate    : false,
                cliente_id  : this.datosLogin.cliente_id
            }
            await apiClientes.getClientes(paramClientes).then((response) => {
                this.itemsClientes = response.data;
            })
            .catch (err => {
                console.log(err);
                Notify.ErrorToast("No se pudieron cargar los clientes");
            })
        },
        async getTiposNominas() {
            let param = {
                activo      : true,
                paginate    : false,
                cliente_id  : this.cliente_value,
                empresa_id  : this.empresa_value
            }
            this.itemsTiposNominas = [];
            await apiTipoNomina.find(param).then((response) => {
                let format = response.data;
                if(format.length != 0){
                    format.forEach( item => {
                        item.claveDescripcion = item.clave + ' - ' + item.descripcion
                        this.itemsTiposNominas.push(item);
                    })
                }
            })
            .catch (err => {
                console.log(err);
                Notify.ErrorToast("No se pudieron cargar los tipos de nómina");
            })
        },
        async getPeriodos(tipoNomID){
            
            let param = {
                activo      : true,
                paginate    : false,
                tipo_nomina_id : tipoNomID
            }
            await apiPeriodos.find(param).then((response) => {
                this.itemsPeriodos = response.data;
                if(this.itemsPeriodos.length != 0){
                    if(this.accion === "add") {
                        let per = this.itemsPeriodos.find( elemento => elemento.actual == true )
                        this.calculoNom.periodo_id = per.id;
                        this.fechaPago = this.dateFormat(per.fecha_de_pago, 'modal');
                    }                    
                }
            })
            .catch (err => {
                console.log(err);
                Notify.ErrorToast("No se pudieron cargar los periodos");
            })
        },
        async getEmpresas(cliente_id){
            let param = {
                activo      : true, 
                paginate    : false,
                cliente_id  : cliente_id
            }
            await apiEmpresas.getEmpresas(param).then((response) => {
                this.itemsEmpresas = response.data;
            })
            .catch (err => {
                console.log(err);
                Notify.ErrorToast("No se pudieron cargar las empresas.");
            })
        },
        dateFormat(fecha, tipo) {
            let fecha2 = fecha.split("-");
            if(tipo == 'modal'){
                return fecha2[2] + "/" + fecha2[1]+ "/" + fecha2[0];
            }else{
                return fecha2[2] + " / " + fecha2[1]+ " / " + fecha2[0];
            }
        },
        
        textColor(estatus){
             switch (estatus) {
                case "Incompleta":
                    return "#F86868";
                    break;
                case "Cancelado":
                    return "#F86868";
                    break;
                case "Acumulado":
                    return "#FF9B05";
                    break;
                case "Timbrado":
                    return "#86EF83";
                    break;
                case undefined:
                    return "";
                    break;
                default:
                    return "#BDBDBD";
                    break;
            }
        },
        desacumularNomina(itemNomina){
            this.loadingLottie =  true;
            this.fraseLoading = "Se esta realizando la desacumulación de la nomina ...";

            setTimeout(() => { this.loadingLottie = false; }, 10000);
        }
    },
    created(){
        this.urlImg = GetUrlImg.getURL();
        this.datosLogin = this.$session.get('usuario');
        this.rol = this.datosLogin.rol.name;
        if (this.rol != "root") {
            this.filters.cliente_id = this.datosLogin.cliente_id;
            this.filters.empresa_id = this.datosLogin.empresa_id;
            this.cliente_value = this.datosLogin.cliente_id;
            this.empresa_value = this.datosLogin.empresa_id;
        }
        if(this.rol == 'root'){
            this.getClientes();
        }
    },
    updated() {
        document.querySelector('#tableScroll thead tr th:nth-child(4)').style.width   = "310px";
        document.querySelector('#tableScroll thead tr th:nth-child(4)').style.paddingRight   = "8px";
        document.querySelector('#tableScroll thead tr th:nth-child(4)').style.paddingLeft   = "8px";

        document.querySelector('#tableScroll thead tr th:nth-child(5)').style.width   = "80px";
        document.querySelector('#tableScroll thead tr th:nth-child(5)').style.paddingRight   = "8px";
        document.querySelector('#tableScroll thead tr th:nth-child(5)').style.paddingLeft   = "8px";
    },

}
</script>

<style scoped>
    #padre {
        padding-left: 40%;
    }
    #loading {
        display: table-cell;
        vertical-align: middle;
    }
    .botonModal.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
        display: none;
    }
</style>
