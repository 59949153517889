<template>
    <div >
        <div class="loading" >
            <lottie :options="options" :height="150" :width="150"/>
        </div>
        <div class="cont-text">
            <h2 class="texto-loading">{{frase}}</h2>
        </div>
    </div>
</template>

<script>
import Lottie from 'vue-lottie';
import animationData from '@/animations/cliker.json';

export default {
    components: {
        'lottie': Lottie
    },
    props: {
        frase: {
            type: String,
            default: ""
        },
        frases: {
            type:Array,
            default:()=>[]
        },
        options: {
            type: Object,
            default: function() {
                return {
                    animationData: animationData
                };
            },
        },
    },
    data: () => ({

    }),
    watch:{
        
    },
    computed: {
                
    },
    methods: {
    },
};
</script>

<style scoped>
    .loading {
        position: absolute;
        z-index: 998;
        width: 100%;
        height: 100%;
        background: radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0, .8));
        background: -webkit-radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0,.8));
        justify-content: center;
        display: flex;
        align-items: center;
        transition: ease-in, 1s;
        top:0;
        left:0px;
    }
    .cont-text{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .texto-loading{
        position: fixed;
        z-index: 999;
        bottom: 18vh;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        align-items: center;
        text-align: center;
        color: #FFFFFF;
    }
</style>
